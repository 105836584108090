import React, { useContext, useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import Axios from "axios"
import { RegionContext } from "@/context/regionContext"
import MdWrapper from "@/components/MarkdownWrap"
function Index(props) {
    const region = useContext(RegionContext)
    const [markdown, setMarkdown] = useState(null)



    useEffect(() => {
        let checkedRegion = ''
        if (region == 'jp') {
            checkedRegion = 'jp'
        } else {
            checkedRegion = 'base'
        }
        let mdURL = `${process.env.resourceUrl}docs/warranty-policy/${checkedRegion}.md`
        Axios.get(mdURL).then(function (data) {
            setMarkdown(data.data)
        })
    }, [])

    return (
        <Layout menuBlack={true}>
            <SEO page="Warranty_Policy" />
            <div style={{ paddingTop: 100 }}></div>
            {markdown != null ? (
                <MdWrapper md={markdown} stl={{ marginTop: 0 }} />
            ) : (
                ""
            )}
        </Layout>
    )
}

export default Index